import React  from 'react'
import '../../assets/css/home.css'
import Wallpaper from "../../assets/images/Home/quarto-principal.webp"
import Instagram from "../../assets/images/Icons/instagram.webp"
import Facebook from "../../assets/images/Icons/facebook.webp"
import Back1 from "../../assets/images/Home/background-1.webp"
import Back2 from "../../assets/images/Home/background-2.webp"

function Home(){
    return(
        <main id="Home">
            <img src={Wallpaper} alt="Background" className='wallpaper' />
            <img src={Back1} alt="Background" className='back-1' />
            <img src={Back2} alt="Background" className='back-2' />
            <header className="header">
                <div className='logo'>
                    <div>Dos Santos</div>
                    <div>Plumbing Services</div>
                </div>
                <div className='social-medias'>
                    <span>FOLLOW</span>
                    <span>US</span>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Unique Building Instagram" className='social-instagram' />    
                    </a>
                    <a href="https://www.facebook.com/110319171987599/posts/pfbid02Thqh5zXSQs4e9X55rjfmt6Ex2Zt2n6bvsxDtesHz7JbkyE9TwxHzK961VkcAeJ3Vl/?sfnsn=scwspwa" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Unique Building Facebook" className='social-facebook' />    
                    </a>
                </div>
            </header>
            <section className="container-description-home">
                <span className='tittle'>
                    <h2>LIVE IN YOUR</h2>
                    <div className='line'></div>
                    <h1>DREAM <span>HOME</span></h1>
                </span>

                <a href='https://dossantosplumbingservices.uk/#ContactUs'>CONTACT US</a>

                <div className='description'>
                    <span className='tittle-description'>
                        <div className='line'></div>
                        <h1>WE FULFILL YOUR DREAMS</h1>
                        <div className='line'></div>
                    </span>
                    <span>We create the best and most welcoming environments thinking about the well being of each client.  We work with quality and excellence in all projects carried out, after all, when you do what you love, success is guaranteed, we build with love and for the love of all our customers</span>
                </div>

            </section>
        </main>
    )
}

export default Home
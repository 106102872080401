import React from "react"
import '../../assets/css/bathroom.css'
import Menu from "../Menu/Menu"
import Rodape from '../Rodape/Rodape'
import Instagram from "../../assets/images/Icons/instagram.webp"
import Facebook from "../../assets/images/Icons/facebook.webp"
import NewWindow from "../../assets/images/Icons/newwindow.webp"

function Contact(){
    
    return(
        <main id="Buildings">
            <Menu/>
            <header className="header-buildings">
                <div className='logo'>
                    <div>Dos Santos</div>
                    <div>Plumbing Services</div>
                </div>
                <div className='social-medias'>
                    <span>FOLLOW</span>
                    <span>US</span>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Unique Building Instagram" className='social-instagram' />    
                    </a>
                    <a href="https://www.facebook.com/110319171987599/posts/pfbid02Thqh5zXSQs4e9X55rjfmt6Ex2Zt2n6bvsxDtesHz7JbkyE9TwxHzK961VkcAeJ3Vl/?sfnsn=scwspwa" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Unique Building Facebook" className='social-facebook' />    
                    </a>
                </div>
            </header>


            <section id="building-images">
                
                <span className="building-tittle">Kitchen</span>
                
                <span className="img imgb-1"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-1')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-2"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-2')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-3"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-3')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-4"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-4')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-5"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-5')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-6"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-6')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-7"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-7')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-8"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-8')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-9"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-9')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-10"><span className="filter" onClick={()=>{window.open('/bathrooms/imgb-10')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-11"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-11')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-12"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-12')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-13"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-13')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-14"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-14')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-15"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-15')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-16"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-16')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-17"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-17')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-18"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-18')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-19"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-19')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-20"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-20')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-21"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-21')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-22"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-22')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-23"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-23')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-24"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-24')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-25"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-25')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-26"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-26')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-27"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-27')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-28"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-28')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-29"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-29')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-30"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-30')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-31"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-31')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-32"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-32')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-33"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-33')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-34"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-34')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-35"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-35')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-36"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-36')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-37"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-37')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-38"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-38')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-39"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-39')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-40"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-40')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-41"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-41')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-42"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-42')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-43"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-43')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-44"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-44')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-45"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-45')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgb-46"><span className="filter" onClick={()=>{window.open('/bathroom/imgb-46')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <Rodape/>
            </section>

        </main>
    )
}

export default Contact
import React from 'react'
import '../../assets/css/menu.css'

function Menu(){
    return(
        <nav id='menu'>
            <div className="mobile-menu" id="mobile-menu" onClick={
                ()=>{
                    document.getElementById("menu-back").classList.toggle("activated")
                    document.getElementById("menu-items").classList.toggle("activated")
                }
            }>
                <div className="line1"></div> 
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
            <div className='menu-back' id='menu-back'></div>
            <div className='menu-back-2' id='menu-back-2'>
                Dos Santos<br/>Plumbing Services</div>
            <ul className='menu' id='menu-items'>
                <li><a href="https://dossantosplumbingservices.uk/#Home">Home</a></li>
                <li><a href="https://dossantosplumbingservices.uk/#Environments">Environments</a></li>
                <li><a href="https://dossantosplumbingservices.uk/#ContactUs">Contact</a></li>
                <li><a href="https://dossantosplumbingservices.uk/buildings" className='buildings-menu'>Buildings</a></li>
                <li className='li-logo-menu'>
                    <a href="https://dossantosplumbingservices.uk/" className='logo-menu'> </a>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" className='insta-menu'> </a>
                    <a href="https://www.facebook.com/uniquebuilding/" className='facebook-menu'> </a>
                </li>
            </ul>
        </nav>
    )
}

export default Menu
import React from "react"
import '../../assets/css/kitchen.css'
import Menu from "../Menu/Menu"
import Rodape from '../Rodape/Rodape'
import Instagram from "../../assets/images/Icons/instagram.webp"
import Facebook from "../../assets/images/Icons/facebook.webp"
import NewWindow from "../../assets/images/Icons/newwindow.webp"

function Contact(){
    
    return(
        <main id="Buildings">
            <Menu/>
            <header className="header-buildings">
                <div className='logo'>
                    <div>Dos Santos</div>
                    <div>Plumbing Services</div>
                </div>
                <div className='social-medias'>
                    <span>FOLLOW</span>
                    <span>US</span>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Unique Building Instagram" className='social-instagram' />    
                    </a>
                    <a href="https://www.facebook.com/110319171987599/posts/pfbid02Thqh5zXSQs4e9X55rjfmt6Ex2Zt2n6bvsxDtesHz7JbkyE9TwxHzK961VkcAeJ3Vl/?sfnsn=scwspwa" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Unique Building Facebook" className='social-facebook' />    
                    </a>
                </div>
            </header>


            <section id="building-images">
                
                <span className="building-tittle">Kitchen</span>
                
                <span className="img imgk-1"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-1')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-2"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-2')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-3"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-3')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-4"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-4')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-5"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-5')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-6"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-6')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-7"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-7')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-8"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-8')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-9"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-9')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-10"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-10')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-11"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-11')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-12"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-12')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-13"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-13')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-14"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-14')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-15"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-15')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-16"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-16')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-17"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-17')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-18"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-18')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-19"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-19')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-20"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-20')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-21"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-21')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-22"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-22')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-23"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-23')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-24"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-24')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-25"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-25')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-26"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-26')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-27"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-27')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-28"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-28')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-29"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-29')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-30"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-30')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-31"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-31')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-32"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-32')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-33"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-33')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-34"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-34')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-35"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-35')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-36"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-36')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-37"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-37')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-38"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-38')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-39"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-39')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-40"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-40')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-41"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-41')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-42"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-42')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-43"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-43')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-44"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-44')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-45"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-45')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-46"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-46')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-47"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-47')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-48"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-48')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-49"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-49')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-50"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-50')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-51"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-51')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-52"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-52')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-53"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-53')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-54"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-54')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-55"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-55')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-56"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-56')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-57"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-57')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img imgk-58"><span className="filter" onClick={()=>{window.open('/kitchen/imgk-58')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <Rodape/>
            </section>

        </main>
    )
}

export default Contact
import React from "react";
import "../../assets/css/error.css"

function Error(){
    return(
        <section id="error-404">
            <h1>4O4</h1>
            <h2>Ooops... You seem to be lost</h2>
            <a href="https://dossantosplumbingservices.uk/">Back to Home</a>
        </section>
    )
}

export default Error;
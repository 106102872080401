import React from "react"
import '../../assets/css/buildings.css'
import Menu from "../Menu/Menu"
import Rodape from '../Rodape/Rodape'
import Instagram from "../../assets/images/Icons/instagram.webp"
import Facebook from "../../assets/images/Icons/facebook.webp"
import NewWindow from "../../assets/images/Icons/newwindow.webp"

function Contact(){
    
    return(
        <main id="Buildings">
            <Menu/>
            <header className="header-buildings">
                <div className='logo'>
                    <div>Dos Santos</div>
                    <div>Plumbing Services</div>
                </div>
                <div className='social-medias'>
                    <span>FOLLOW</span>
                    <span>US</span>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Unique Building Instagram" className='social-instagram' />    
                    </a>
                    <a href="https://www.facebook.com/110319171987599/posts/pfbid02Thqh5zXSQs4e9X55rjfmt6Ex2Zt2n6bvsxDtesHz7JbkyE9TwxHzK961VkcAeJ3Vl/?sfnsn=scwspwa" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Unique Building Facebook" className='social-facebook' />    
                    </a>
                </div>
            </header>


            <section id="building-images">
                
                <span className="building-tittle">Buildings</span>
                
                <span className="img img-1"><span className="filter" onClick={()=>{window.open('/buildings/img-1')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-2"><span className="filter" onClick={()=>{window.open('/buildings/img-2')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-3"><span className="filter" onClick={()=>{window.open('/buildings/img-3')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-4"><span className="filter" onClick={()=>{window.open('/buildings/img-4')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-5"><span className="filter" onClick={()=>{window.open('/buildings/img-5')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-6"><span className="filter" onClick={()=>{window.open('/buildings/img-6')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-7"><span className="filter" onClick={()=>{window.open('/buildings/img-7')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-8"><span className="filter" onClick={()=>{window.open('/buildings/img-8')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-9"><span className="filter" onClick={()=>{window.open('/buildings/img-9')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-10"><span className="filter" onClick={()=>{window.open('/buildings/img-10')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-11"><span className="filter" onClick={()=>{window.open('/buildings/img-11')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-12"><span className="filter" onClick={()=>{window.open('/buildings/img-12')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-13"><span className="filter" onClick={()=>{window.open('/buildings/img-13')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-14"><span className="filter" onClick={()=>{window.open('/buildings/img-14')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-15"><span className="filter" onClick={()=>{window.open('/buildings/img-15')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-16"><span className="filter" onClick={()=>{window.open('/buildings/img-16')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-17"><span className="filter" onClick={()=>{window.open('/buildings/img-17')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-18"><span className="filter" onClick={()=>{window.open('/buildings/img-18')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-19"><span className="filter" onClick={()=>{window.open('/buildings/img-19')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-20"><span className="filter" onClick={()=>{window.open('/buildings/img-20')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-21"><span className="filter" onClick={()=>{window.open('/buildings/img-21')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-22"><span className="filter" onClick={()=>{window.open('/buildings/img-22')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-23"><span className="filter" onClick={()=>{window.open('/buildings/img-23')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-24"><span className="filter" onClick={()=>{window.open('/buildings/img-24')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-25"><span className="filter" onClick={()=>{window.open('/buildings/img-25')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-26"><span className="filter" onClick={()=>{window.open('/buildings/img-26')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-27"><span className="filter" onClick={()=>{window.open('/buildings/img-27')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-28"><span className="filter" onClick={()=>{window.open('/buildings/img-28')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-29"><span className="filter" onClick={()=>{window.open('/buildings/img-29')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-30"><span className="filter" onClick={()=>{window.open('/buildings/img-30')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-31"><span className="filter" onClick={()=>{window.open('/buildings/img-31')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-32"><span className="filter" onClick={()=>{window.open('/buildings/img-32')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-33"><span className="filter" onClick={()=>{window.open('/buildings/img-33')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-34"><span className="filter" onClick={()=>{window.open('/buildings/img-34')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-35"><span className="filter" onClick={()=>{window.open('/buildings/img-35')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-36"><span className="filter" onClick={()=>{window.open('/buildings/img-36')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-37"><span className="filter" onClick={()=>{window.open('/buildings/img-37')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-38"><span className="filter" onClick={()=>{window.open('/buildings/img-38')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-39"><span className="filter" onClick={()=>{window.open('/buildings/img-39')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-40"><span className="filter" onClick={()=>{window.open('/buildings/img-40')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-41"><span className="filter" onClick={()=>{window.open('/buildings/img-41')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-42"><span className="filter" onClick={()=>{window.open('/buildings/img-42')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-43"><span className="filter" onClick={()=>{window.open('/buildings/img-43')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-44"><span className="filter" onClick={()=>{window.open('/buildings/img-44')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-45"><span className="filter" onClick={()=>{window.open('/buildings/img-45')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-46"><span className="filter" onClick={()=>{window.open('/buildings/img-46')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-47"><span className="filter" onClick={()=>{window.open('/buildings/img-47')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-48"><span className="filter" onClick={()=>{window.open('/buildings/img-48')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-49"><span className="filter" onClick={()=>{window.open('/buildings/img-49')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-50"><span className="filter" onClick={()=>{window.open('/buildings/img-50')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-51"><span className="filter" onClick={()=>{window.open('/buildings/img-51')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-52"><span className="filter" onClick={()=>{window.open('/buildings/img-52')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-53"><span className="filter" onClick={()=>{window.open('/buildings/img-53')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-54"><span className="filter" onClick={()=>{window.open('/buildings/img-54')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-55"><span className="filter" onClick={()=>{window.open('/buildings/img-55')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-56"><span className="filter" onClick={()=>{window.open('/buildings/img-56')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-57"><span className="filter" onClick={()=>{window.open('/buildings/img-57')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-58"><span className="filter" onClick={()=>{window.open('/buildings/img-58')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-59"><span className="filter" onClick={()=>{window.open('/buildings/img-59')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-60"><span className="filter" onClick={()=>{window.open('/buildings/img-60')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-61"><span className="filter" onClick={()=>{window.open('/buildings/img-61')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-62"><span className="filter" onClick={()=>{window.open('/buildings/img-62')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-63"><span className="filter" onClick={()=>{window.open('/buildings/img-63')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-64"><span className="filter" onClick={()=>{window.open('/buildings/img-64')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-65"><span className="filter" onClick={()=>{window.open('/buildings/img-65')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-66"><span className="filter" onClick={()=>{window.open('/buildings/img-66')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-67"><span className="filter" onClick={()=>{window.open('/buildings/img-67')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-68"><span className="filter" onClick={()=>{window.open('/buildings/img-68')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-69"><span className="filter" onClick={()=>{window.open('/buildings/img-69')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-70"><span className="filter" onClick={()=>{window.open('/buildings/img-70')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-71"><span className="filter" onClick={()=>{window.open('/buildings/img-71')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-72"><span className="filter" onClick={()=>{window.open('/buildings/img-72')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-73"><span className="filter" onClick={()=>{window.open('/buildings/img-73')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-74"><span className="filter" onClick={()=>{window.open('/buildings/img-74')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-75"><span className="filter" onClick={()=>{window.open('/buildings/img-75')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-76"><span className="filter" onClick={()=>{window.open('/buildings/img-76')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-77"><span className="filter" onClick={()=>{window.open('/buildings/img-77')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-78"><span className="filter" onClick={()=>{window.open('/buildings/img-78')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-79"><span className="filter" onClick={()=>{window.open('/buildings/img-79')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-80"><span className="filter" onClick={()=>{window.open('/buildings/img-80')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-81"><span className="filter" onClick={()=>{window.open('/buildings/img-81')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-82"><span className="filter" onClick={()=>{window.open('/buildings/img-82')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-83"><span className="filter" onClick={()=>{window.open('/buildings/img-83')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-84"><span className="filter" onClick={()=>{window.open('/buildings/img-84')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-85"><span className="filter" onClick={()=>{window.open('/buildings/img-85')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-86"><span className="filter" onClick={()=>{window.open('/buildings/img-86')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-87"><span className="filter" onClick={()=>{window.open('/buildings/img-87')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-88"><span className="filter" onClick={()=>{window.open('/buildings/img-88')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-89"><span className="filter" onClick={()=>{window.open('/buildings/img-89')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-90"><span className="filter" onClick={()=>{window.open('/buildings/img-90')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-91"><span className="filter" onClick={()=>{window.open('/buildings/img-91')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-92"><span className="filter" onClick={()=>{window.open('/buildings/img-92')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-93"><span className="filter" onClick={()=>{window.open('/buildings/img-93')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-94"><span className="filter" onClick={()=>{window.open('/buildings/img-94')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-95"><span className="filter" onClick={()=>{window.open('/buildings/img-95')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-96"><span className="filter" onClick={()=>{window.open('/buildings/img-96')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-97"><span className="filter" onClick={()=>{window.open('/buildings/img-97')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-98"><span className="filter" onClick={()=>{window.open('/buildings/img-98')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-99"><span className="filter" onClick={()=>{window.open('/buildings/img-99')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-100"><span className="filter" onClick={()=>{window.open('/buildings/img-100')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-101"><span className="filter" onClick={()=>{window.open('/buildings/img-101')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-102"><span className="filter" onClick={()=>{window.open('/buildings/img-102')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-103"><span className="filter" onClick={()=>{window.open('/buildings/img-103')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-104"><span className="filter" onClick={()=>{window.open('/buildings/img-104')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-105"><span className="filter" onClick={()=>{window.open('/buildings/img-105')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <span className="img img-106"><span className="filter" onClick={()=>{window.open('/buildings/img-106')}}></span><img loading="lazy" src={NewWindow} alt=""/></span>
                <Rodape/>
            </section>

        </main>
    )
}

export default Contact
import React from 'react'
import {useParams} from 'react-router-dom'
import Img1 from '../../assets/images/KitchenImg/kitchen (1).jpg'
import Img2 from '../../assets/images/KitchenImg/kitchen (2).jpg'
import Img3 from '../../assets/images/KitchenImg/kitchen (3).jpg'
import Img4 from '../../assets/images/KitchenImg/kitchen (4).jpg'
import Img5 from '../../assets/images/KitchenImg/kitchen (5).jpg'
import Img6 from '../../assets/images/KitchenImg/kitchen (6).jpg'
import Img7 from '../../assets/images/KitchenImg/kitchen (7).jpg'
import Img8 from '../../assets/images/KitchenImg/kitchen (8).jpg'
import Img9 from '../../assets/images/KitchenImg/kitchen (9).jpg'
import Img10 from '../../assets/images/KitchenImg/kitchen (10).jpg'
import Img11 from '../../assets/images/KitchenImg/kitchen (11).jpg'
import Img12 from '../../assets/images/KitchenImg/kitchen (12).jpg'
import Img13 from '../../assets/images/KitchenImg/kitchen (13).jpg'
import Img14 from '../../assets/images/KitchenImg/kitchen (14).jpg'
import Img15 from '../../assets/images/KitchenImg/kitchen (15).jpg'
import Img16 from '../../assets/images/KitchenImg/kitchen (16).jpg'
import Img17 from '../../assets/images/KitchenImg/kitchen (17).jpg'
import Img18 from '../../assets/images/KitchenImg/kitchen (18).jpg'
import Img19 from '../../assets/images/KitchenImg/kitchen (19).jpg'
import Img20 from '../../assets/images/KitchenImg/kitchen (20).jpg'
import Img21 from '../../assets/images/KitchenImg/kitchen (21).jpg'
import Img22 from '../../assets/images/KitchenImg/kitchen (22).jpg'
import Img23 from '../../assets/images/KitchenImg/kitchen (23).jpg'
import Img24 from '../../assets/images/KitchenImg/kitchen (24).jpg'
import Img25 from '../../assets/images/KitchenImg/kitchen (25).jpg'
import Img26 from '../../assets/images/KitchenImg/kitchen (26).jpg'
import Img27 from '../../assets/images/KitchenImg/kitchen (27).jpg'
import Img28 from '../../assets/images/KitchenImg/kitchen (28).jpg'
import Img29 from '../../assets/images/KitchenImg/kitchen (29).jpg'
import Img30 from '../../assets/images/KitchenImg/kitchen (30).jpg'
import Img31 from '../../assets/images/KitchenImg/kitchen (31).jpg'
import Img32 from '../../assets/images/KitchenImg/kitchen (32).jpg'
import Img33 from '../../assets/images/KitchenImg/kitchen (33).jpg'
import Img34 from '../../assets/images/KitchenImg/kitchen (34).jpg'
import Img35 from '../../assets/images/KitchenImg/kitchen (35).jpg'
import Img36 from '../../assets/images/KitchenImg/kitchen (36).jpg'
import Img37 from '../../assets/images/KitchenImg/kitchen (37).jpg'
import Img38 from '../../assets/images/KitchenImg/kitchen (38).jpg'
import Img39 from '../../assets/images/KitchenImg/kitchen (39).jpg'
import Img40 from '../../assets/images/KitchenImg/kitchen (40).jpg'
import Img41 from '../../assets/images/KitchenImg/kitchen (41).jpg'
import Img42 from '../../assets/images/KitchenImg/kitchen (42).jpg'
import Img43 from '../../assets/images/KitchenImg/kitchen (43).jpg'
import Img44 from '../../assets/images/KitchenImg/kitchen (44).jpg'
import Img45 from '../../assets/images/KitchenImg/kitchen (45).jpg'
import Img46 from '../../assets/images/KitchenImg/kitchen (46).jpg'
import Img47 from '../../assets/images/KitchenImg/kitchen (47).jpg'
import Img48 from '../../assets/images/KitchenImg/kitchen (48).jpg'
import Img49 from '../../assets/images/KitchenImg/kitchen (49).jpg'
import Img50 from '../../assets/images/KitchenImg/kitchen (50).jpg'
import Img51 from '../../assets/images/KitchenImg/kitchen (51).jpg'
import Img52 from '../../assets/images/KitchenImg/kitchen (52).jpg'
import Img53 from '../../assets/images/KitchenImg/kitchen (53).jpg'
import Img54 from '../../assets/images/KitchenImg/kitchen (54).jpg'
import Img55 from '../../assets/images/KitchenImg/kitchen (55).jpg'
import Img56 from '../../assets/images/KitchenImg/kitchen (56).jpg'
import Img57 from '../../assets/images/KitchenImg/kitchen (57).jpg'
import Img58 from '../../assets/images/KitchenImg/kitchen (58).jpg'

import '../../assets/css/buildingImg.css'
import Menu from '../Menu/Menu'

function Image(){

    const { img } = useParams(); 

    return(
        <main id='image-building'>
            <Menu/>
            {img==="imgk-1" ? <img src={Img1 } alt=""/> : null} 
            {img==="imgk-2" ? <img src={Img2 } alt=""/> : null} 
            {img==="imgk-3" ? <img src={Img3 } alt=""/> : null} 
            {img==="imgk-4" ? <img src={Img4 } alt=""/> : null} 
            {img==="imgk-5" ? <img src={Img5 } alt=""/> : null} 
            {img==="imgk-6" ? <img src={Img6 } alt=""/> : null} 
            {img==="imgk-7" ? <img src={Img7 } alt=""/> : null} 
            {img==="imgk-8" ? <img src={Img8 } alt=""/> : null} 
            {img==="imgk-9" ? <img src={Img9 } alt=""/> : null} 
            {img==="imgk-10" ? <img src={Img10} alt=""/> : null} 
            {img==="imgk-11" ? <img src={Img11} alt=""/> : null} 
            {img==="imgk-12" ? <img src={Img12} alt=""/> : null} 
            {img==="imgk-13" ? <img src={Img13} alt=""/> : null} 
            {img==="imgk-14" ? <img src={Img14} alt=""/> : null} 
            {img==="imgk-15" ? <img src={Img15} alt=""/> : null} 
            {img==="imgk-16" ? <img src={Img16} alt=""/> : null} 
            {img==="imgk-17" ? <img src={Img17} alt=""/> : null} 
            {img==="imgk-18" ? <img src={Img18} alt=""/> : null} 
            {img==="imgk-19" ? <img src={Img19} alt=""/> : null} 
            {img==="imgk-20" ? <img src={Img20} alt=""/> : null} 
            {img==="imgk-21" ? <img src={Img21} alt=""/> : null} 
            {img==="imgk-22" ? <img src={Img22} alt=""/> : null} 
            {img==="imgk-23" ? <img src={Img23} alt=""/> : null} 
            {img==="imgk-24" ? <img src={Img24} alt=""/> : null} 
            {img==="imgk-25" ? <img src={Img25} alt=""/> : null} 
            {img==="imgk-26" ? <img src={Img26} alt=""/> : null} 
            {img==="imgk-27" ? <img src={Img27} alt=""/> : null} 
            {img==="imgk-28" ? <img src={Img28} alt=""/> : null} 
            {img==="imgk-29" ? <img src={Img29} alt=""/> : null} 
            {img==="imgk-30" ? <img src={Img30} alt=""/> : null} 
            {img==="imgk-31" ? <img src={Img31} alt=""/> : null} 
            {img==="imgk-32" ? <img src={Img32} alt=""/> : null} 
            {img==="imgk-33" ? <img src={Img33} alt=""/> : null} 
            {img==="imgk-34" ? <img src={Img34} alt=""/> : null} 
            {img==="imgk-35" ? <img src={Img35} alt=""/> : null} 
            {img==="imgk-36" ? <img src={Img36} alt=""/> : null} 
            {img==="imgk-37" ? <img src={Img37} alt=""/> : null} 
            {img==="imgk-38" ? <img src={Img38} alt=""/> : null} 
            {img==="imgk-39" ? <img src={Img39} alt=""/> : null} 
            {img==="imgk-40" ? <img src={Img40} alt=""/> : null} 
            {img==="imgk-41" ? <img src={Img41} alt=""/> : null} 
            {img==="imgk-42" ? <img src={Img42} alt=""/> : null} 
            {img==="imgk-43" ? <img src={Img43} alt=""/> : null} 
            {img==="imgk-44" ? <img src={Img44} alt=""/> : null} 
            {img==="imgk-45" ? <img src={Img45} alt=""/> : null} 
            {img==="imgk-46" ? <img src={Img46} alt=""/> : null} 
            {img==="imgk-47" ? <img src={Img47} alt=""/> : null} 
            {img==="imgk-48" ? <img src={Img48} alt=""/> : null} 
            {img==="imgk-49" ? <img src={Img49} alt=""/> : null} 
            {img==="imgk-50" ? <img src={Img50} alt=""/> : null} 
            {img==="imgk-51" ? <img src={Img51} alt=""/> : null} 
            {img==="imgk-52" ? <img src={Img52} alt=""/> : null} 
            {img==="imgk-53" ? <img src={Img53} alt=""/> : null} 
            {img==="imgk-54" ? <img src={Img54} alt=""/> : null} 
            {img==="imgk-55" ? <img src={Img55} alt=""/> : null} 
            {img==="imgk-56" ? <img src={Img56} alt=""/> : null} 
            {img==="imgk-57" ? <img src={Img57} alt=""/> : null} 
            {img==="imgk-58" ? <img src={Img58} alt=""/> : null}
        </main>
    )
}

export default Image
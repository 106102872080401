import React from 'react'
import {useParams} from 'react-router-dom'
import Img1 from '../../assets/images/BathroomImg/bathroom (1).jpg'
import Img2 from '../../assets/images/BathroomImg/bathroom (2).jpg'
import Img3 from '../../assets/images/BathroomImg/bathroom (3).jpg'
import Img4 from '../../assets/images/BathroomImg/bathroom (4).jpg'
import Img5 from '../../assets/images/BathroomImg/bathroom (5).jpg'
import Img6 from '../../assets/images/BathroomImg/bathroom (6).jpg'
import Img7 from '../../assets/images/BathroomImg/bathroom (7).jpg'
import Img8 from '../../assets/images/BathroomImg/bathroom (8).jpg'
import Img9 from '../../assets/images/BathroomImg/bathroom (9).jpg'
import Img10 from '../../assets/images/BathroomImg/bathroom (10).jpg'
import Img11 from '../../assets/images/BathroomImg/bathroom (11).jpg'
import Img12 from '../../assets/images/BathroomImg/bathroom (12).jpg'
import Img13 from '../../assets/images/BathroomImg/bathroom (13).jpg'
import Img14 from '../../assets/images/BathroomImg/bathroom (14).jpg'
import Img15 from '../../assets/images/BathroomImg/bathroom (15).jpg'
import Img16 from '../../assets/images/BathroomImg/bathroom (16).jpg'
import Img17 from '../../assets/images/BathroomImg/bathroom (17).jpg'
import Img18 from '../../assets/images/BathroomImg/bathroom (18).jpg'
import Img19 from '../../assets/images/BathroomImg/bathroom (19).jpg'
import Img20 from '../../assets/images/BathroomImg/bathroom (20).jpg'
import Img21 from '../../assets/images/BathroomImg/bathroom (21).jpg'
import Img22 from '../../assets/images/BathroomImg/bathroom (22).jpg'
import Img23 from '../../assets/images/BathroomImg/bathroom (23).jpg'
import Img24 from '../../assets/images/BathroomImg/bathroom (24).jpg'
import Img25 from '../../assets/images/BathroomImg/bathroom (25).jpg'
import Img26 from '../../assets/images/BathroomImg/bathroom (26).jpg'
import Img27 from '../../assets/images/BathroomImg/bathroom (27).jpg'
import Img28 from '../../assets/images/BathroomImg/bathroom (28).jpg'
import Img29 from '../../assets/images/BathroomImg/bathroom (29).jpg'
import Img30 from '../../assets/images/BathroomImg/bathroom (30).jpg'
import Img31 from '../../assets/images/BathroomImg/bathroom (31).jpg'
import Img32 from '../../assets/images/BathroomImg/bathroom (32).jpg'
import Img33 from '../../assets/images/BathroomImg/bathroom (33).jpg'
import Img34 from '../../assets/images/BathroomImg/bathroom (34).jpg'
import Img35 from '../../assets/images/BathroomImg/bathroom (35).jpg'
import Img36 from '../../assets/images/BathroomImg/bathroom (36).jpg'
import Img37 from '../../assets/images/BathroomImg/bathroom (37).jpg'
import Img38 from '../../assets/images/BathroomImg/bathroom (38).jpg'
import Img39 from '../../assets/images/BathroomImg/bathroom (39).jpg'
import Img40 from '../../assets/images/BathroomImg/bathroom (40).jpg'
import Img41 from '../../assets/images/BathroomImg/bathroom (41).jpg'
import Img42 from '../../assets/images/BathroomImg/bathroom (42).jpg'
import Img43 from '../../assets/images/BathroomImg/bathroom (43).jpg'
import Img44 from '../../assets/images/BathroomImg/bathroom (44).jpg'
import Img45 from '../../assets/images/BathroomImg/bathroom (45).jpg'
import Img46 from '../../assets/images/BathroomImg/bathroom (46).jpg'

import '../../assets/css/buildingImg.css'
import Menu from '../Menu/Menu'

function Image(){

    const { img } = useParams(); 

    return(
        <main id='image-building'>
            <Menu/>
            {img==="imgb-1" ? <img src={Img1 } alt=""/> : null} 
            {img==="imgb-2" ? <img src={Img2 } alt=""/> : null} 
            {img==="imgb-3" ? <img src={Img3 } alt=""/> : null} 
            {img==="imgb-4" ? <img src={Img4 } alt=""/> : null} 
            {img==="imgb-5" ? <img src={Img5 } alt=""/> : null} 
            {img==="imgb-6" ? <img src={Img6 } alt=""/> : null} 
            {img==="imgb-7" ? <img src={Img7 } alt=""/> : null} 
            {img==="imgb-8" ? <img src={Img8 } alt=""/> : null} 
            {img==="imgb-9" ? <img src={Img9 } alt=""/> : null} 
            {img==="imgb-10" ? <img src={Img10} alt=""/> : null} 
            {img==="imgb-11" ? <img src={Img11} alt=""/> : null} 
            {img==="imgb-12" ? <img src={Img12} alt=""/> : null} 
            {img==="imgb-13" ? <img src={Img13} alt=""/> : null} 
            {img==="imgb-14" ? <img src={Img14} alt=""/> : null} 
            {img==="imgb-15" ? <img src={Img15} alt=""/> : null} 
            {img==="imgb-16" ? <img src={Img16} alt=""/> : null} 
            {img==="imgb-17" ? <img src={Img17} alt=""/> : null} 
            {img==="imgb-18" ? <img src={Img18} alt=""/> : null} 
            {img==="imgb-19" ? <img src={Img19} alt=""/> : null} 
            {img==="imgb-20" ? <img src={Img20} alt=""/> : null} 
            {img==="imgb-21" ? <img src={Img21} alt=""/> : null} 
            {img==="imgb-22" ? <img src={Img22} alt=""/> : null} 
            {img==="imgb-23" ? <img src={Img23} alt=""/> : null} 
            {img==="imgb-24" ? <img src={Img24} alt=""/> : null} 
            {img==="imgb-25" ? <img src={Img25} alt=""/> : null} 
            {img==="imgb-26" ? <img src={Img26} alt=""/> : null} 
            {img==="imgb-27" ? <img src={Img27} alt=""/> : null} 
            {img==="imgb-28" ? <img src={Img28} alt=""/> : null} 
            {img==="imgb-29" ? <img src={Img29} alt=""/> : null} 
            {img==="imgb-30" ? <img src={Img30} alt=""/> : null} 
            {img==="imgb-31" ? <img src={Img31} alt=""/> : null} 
            {img==="imgb-32" ? <img src={Img32} alt=""/> : null} 
            {img==="imgb-33" ? <img src={Img33} alt=""/> : null} 
            {img==="imgb-34" ? <img src={Img34} alt=""/> : null} 
            {img==="imgb-35" ? <img src={Img35} alt=""/> : null} 
            {img==="imgb-36" ? <img src={Img36} alt=""/> : null} 
            {img==="imgb-37" ? <img src={Img37} alt=""/> : null} 
            {img==="imgb-38" ? <img src={Img38} alt=""/> : null} 
            {img==="imgb-39" ? <img src={Img39} alt=""/> : null} 
            {img==="imgb-40" ? <img src={Img40} alt=""/> : null} 
            {img==="imgb-41" ? <img src={Img41} alt=""/> : null} 
            {img==="imgb-42" ? <img src={Img42} alt=""/> : null} 
            {img==="imgb-43" ? <img src={Img43} alt=""/> : null} 
            {img==="imgb-44" ? <img src={Img44} alt=""/> : null} 
            {img==="imgb-45" ? <img src={Img45} alt=""/> : null} 
            {img==="imgb-46" ? <img src={Img46} alt=""/> : null} 
            
        </main>
    )
}

export default Image
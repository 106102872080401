import "../src/assets/css/index.css"
import React from 'react'
import ReactDOM from 'react-dom/client'
import Page from './page'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Page />
  </React.StrictMode>,
)

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './App'
import Error from './components/Error-404/Error'
import Buildings from './components/Buildings/Buildings'
import BuildingImg from './components/Buildings/BuildingImg'
import Kitchen from './components/Kitchen/Buildings'
import KitchenImg from './components/Kitchen/BuildingImg'
import Bathroom from './components/Bathroom/Buildings'
import BathroomImg from './components/Bathroom/BuildingImg'

function RoutesApp(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/buildings" element={<Buildings/>}/>
                <Route path="/buildings/:img" element={<BuildingImg/>}/>
                <Route path="/kitchen" element={<Kitchen/>}/>
                <Route path="/kitchen/:img" element={<KitchenImg/>}/>
                <Route path="/bathroom" element={<Bathroom/>}/>
                <Route path="/bathroom/:img" element={<BathroomImg/>}/>
                <Route path="*" element={<Error/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp
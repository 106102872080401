import React from 'react'
import {useParams} from 'react-router-dom'
import Img1 from '../../assets/images/Buildings/building-1.jpg'
import Img2 from '../../assets/images/Buildings/building-2.jpg'
import Img3 from '../../assets/images/Buildings/building-3.jpg'
import Img4 from '../../assets/images/Buildings/building-4.jpg'
import Img5 from '../../assets/images/Buildings/building-5.jpg'
import Img6 from '../../assets/images/Buildings/building-6.jpg'
import Img7 from '../../assets/images/Buildings/building-7.jpg'
import Img8 from '../../assets/images/Buildings/building-8.jpg'
import Img9 from '../../assets/images/Buildings/building-9.jpg'
import Img10 from '../../assets/images/Buildings/building-10.jpg'
import Img11 from '../../assets/images/Buildings/building-11.jpg'
import Img12 from '../../assets/images/Buildings/building-12.jpg'
import Img13 from '../../assets/images/Buildings/building-13.jpg'
import Img14 from '../../assets/images/Buildings/building-14.jpg'
import Img15 from '../../assets/images/Buildings/building-15.jpg'
import Img16 from '../../assets/images/Buildings/building-16.jpg'
import Img17 from '../../assets/images/Buildings/building-17.jpg'
import Img18 from '../../assets/images/Buildings/building-18.jpg'
import Img19 from '../../assets/images/Buildings/building-19.jpg'
import Img20 from '../../assets/images/Buildings/building-20.jpg'
import Img21 from '../../assets/images/Buildings/building-21.jpg'
import Img22 from '../../assets/images/Buildings/building-22.jpg'
import Img23 from '../../assets/images/Buildings/building-23.jpg'
import Img24 from '../../assets/images/Buildings/building-24.jpg'
import Img25 from '../../assets/images/Buildings/building-25.jpg'
import Img26 from '../../assets/images/Buildings/building-26.jpg'
import Img27 from '../../assets/images/Buildings/building-27.jpg'
import Img28 from '../../assets/images/Buildings/building-28.jpg'
import Img29 from '../../assets/images/Buildings/building-29.jpg'
import Img30 from '../../assets/images/Buildings/building-30.jpg'
import Img31 from '../../assets/images/Buildings/building-31.jpg'
import Img32 from '../../assets/images/Buildings/building-32.jpg'
import Img33 from '../../assets/images/Buildings/building-33.jpg'
import Img34 from '../../assets/images/Buildings/building-34.jpg'
import Img35 from '../../assets/images/Buildings/building-35.jpg'
import Img36 from '../../assets/images/Buildings/building-36.jpg'
import Img37 from '../../assets/images/Buildings/building-37.jpg'
import Img38 from '../../assets/images/Buildings/building-38.jpg'
import Img39 from '../../assets/images/Buildings/building-39.jpg'
import Img40 from '../../assets/images/Buildings/building-40.jpg'
import Img41 from '../../assets/images/Buildings/building-41.jpg'
import Img42 from '../../assets/images/Buildings/building-42.jpg'
import Img43 from '../../assets/images/Buildings/building-43.jpg'
import Img44 from '../../assets/images/Buildings/building-44.jpg'
import Img45 from '../../assets/images/Buildings/building-45.jpg'
import Img46 from '../../assets/images/Buildings/building-46.jpg'
import Img47 from '../../assets/images/Buildings/building-47.jpg'
import Img48 from '../../assets/images/Buildings/building-48.jpg'
import Img49 from '../../assets/images/Buildings/building-49.jpg'
import Img50 from '../../assets/images/Buildings/building-50.jpg'
import Img51 from '../../assets/images/Buildings/building-51.jpg'
import Img52 from '../../assets/images/Buildings/building-52.jpg'
import Img53 from '../../assets/images/Buildings/building-53.jpg'
import Img54 from '../../assets/images/Buildings/building-54.jpg'
import Img55 from '../../assets/images/Buildings/building-55.jpg'
import Img56 from '../../assets/images/Buildings/building-56.jpg'
import Img57 from '../../assets/images/Buildings/building-57.jpg'
import Img58 from '../../assets/images/Buildings/building-58.jpg'
import Img59 from '../../assets/images/Buildings/building-59.jpg'
import Img60 from '../../assets/images/Buildings/building-50.jpg'
import Img61 from '../../assets/images/Buildings/building-61.jpg'
import Img62 from '../../assets/images/Buildings/building-62.jpg'
import Img63 from '../../assets/images/Buildings/building-63.jpg'
import Img64 from '../../assets/images/Buildings/building-64.jpg'
import Img65 from '../../assets/images/Buildings/building-65.jpg'
import Img66 from '../../assets/images/Buildings/building-66.jpg'
import Img67 from '../../assets/images/Buildings/building-67.jpg'
import Img68 from '../../assets/images/Buildings/building-68.jpg'
import Img69 from '../../assets/images/Buildings/building-69.jpg'
import Img70 from '../../assets/images/Buildings/building-70.jpg'
import Img71 from '../../assets/images/Buildings/building-71.jpg'
import Img72 from '../../assets/images/Buildings/building-72.jpg'
import Img73 from '../../assets/images/Buildings/building-73.jpg'
import Img74 from '../../assets/images/Buildings/building-74.jpg'
import Img75 from '../../assets/images/Buildings/building-75.jpg'
import Img76 from '../../assets/images/Buildings/building-76.jpg'
import Img77 from '../../assets/images/Buildings/building-77.jpg'
import Img78 from '../../assets/images/Buildings/building-78.jpg'
import Img79 from '../../assets/images/Buildings/building-79.jpg'
import Img80 from '../../assets/images/Buildings/building-80.jpg'
import Img81 from '../../assets/images/Buildings/building-81.jpg'
import Img82 from '../../assets/images/Buildings/building-82.jpg'
import Img83 from '../../assets/images/Buildings/building-83.jpg'
import Img84 from '../../assets/images/Buildings/building-84.jpg'
import Img85 from '../../assets/images/Buildings/building-85.jpg'
import Img86 from '../../assets/images/Buildings/building-86.jpg'
import Img87 from '../../assets/images/Buildings/building-87.jpg'
import Img88 from '../../assets/images/Buildings/building-88.jpg'
import Img89 from '../../assets/images/Buildings/building-89.jpg'
import Img90 from '../../assets/images/Buildings/building-90.jpg'
import Img91 from '../../assets/images/Buildings/building-91.jpg'
import Img92 from '../../assets/images/Buildings/building-92.jpg'
import Img93 from '../../assets/images/Buildings/building-93.jpg'
import Img94 from '../../assets/images/Buildings/building-94.jpg'
import Img95 from '../../assets/images/Buildings/building-95.jpg'
import Img96 from '../../assets/images/Buildings/building-96.jpg'
import Img97 from '../../assets/images/Buildings/building-97.jpg'
import Img98 from '../../assets/images/Buildings/building-98.jpg'
import Img99 from '../../assets/images/Buildings/building-99.jpg'
import Img100 from '../../assets/images/Buildings/building-100.jpg'
import Img101 from '../../assets/images/Buildings/building-101.jpg'
import Img102 from '../../assets/images/Buildings/building-102.jpg'
import Img103 from '../../assets/images/Buildings/building-103.jpg'
import Img104 from '../../assets/images/Buildings/building-104.jpg'
import Img105 from '../../assets/images/Buildings/building-105.jpg'
import Img106 from '../../assets/images/Buildings/building-106.jpg'

import '../../assets/css/buildingImg.css'
import Menu from '../Menu/Menu'

function Image(){

    const { img } = useParams(); 

    return(
        <main id='image-building'>
            <Menu/>
            {img==="img-1" ? <img src={Img1 } alt=""/> : null} 
            {img==="img-2" ? <img src={Img2 } alt=""/> : null} 
            {img==="img-3" ? <img src={Img3 } alt=""/> : null} 
            {img==="img-4" ? <img src={Img4 } alt=""/> : null} 
            {img==="img-5" ? <img src={Img5 } alt=""/> : null} 
            {img==="img-6" ? <img src={Img6 } alt=""/> : null} 
            {img==="img-7" ? <img src={Img7 } alt=""/> : null} 
            {img==="img-8" ? <img src={Img8 } alt=""/> : null} 
            {img==="img-9" ? <img src={Img9 } alt=""/> : null} 
            {img==="img-10" ? <img src={Img10} alt=""/> : null} 
            {img==="img-11" ? <img src={Img11} alt=""/> : null} 
            {img==="img-12" ? <img src={Img12} alt=""/> : null} 
            {img==="img-13" ? <img src={Img13} alt=""/> : null} 
            {img==="img-14" ? <img src={Img14} alt=""/> : null} 
            {img==="img-15" ? <img src={Img15} alt=""/> : null} 
            {img==="img-16" ? <img src={Img16} alt=""/> : null} 
            {img==="img-17" ? <img src={Img17} alt=""/> : null} 
            {img==="img-18" ? <img src={Img18} alt=""/> : null} 
            {img==="img-19" ? <img src={Img19} alt=""/> : null} 
            {img==="img-20" ? <img src={Img20} alt=""/> : null} 
            {img==="img-21" ? <img src={Img21} alt=""/> : null} 
            {img==="img-22" ? <img src={Img22} alt=""/> : null} 
            {img==="img-23" ? <img src={Img23} alt=""/> : null} 
            {img==="img-24" ? <img src={Img24} alt=""/> : null} 
            {img==="img-25" ? <img src={Img25} alt=""/> : null} 
            {img==="img-26" ? <img src={Img26} alt=""/> : null} 
            {img==="img-27" ? <img src={Img27} alt=""/> : null} 
            {img==="img-28" ? <img src={Img28} alt=""/> : null} 
            {img==="img-29" ? <img src={Img29} alt=""/> : null} 
            {img==="img-30" ? <img src={Img30} alt=""/> : null} 
            {img==="img-31" ? <img src={Img31} alt=""/> : null} 
            {img==="img-32" ? <img src={Img32} alt=""/> : null} 
            {img==="img-33" ? <img src={Img33} alt=""/> : null} 
            {img==="img-34" ? <img src={Img34} alt=""/> : null} 
            {img==="img-35" ? <img src={Img35} alt=""/> : null} 
            {img==="img-36" ? <img src={Img36} alt=""/> : null} 
            {img==="img-37" ? <img src={Img37} alt=""/> : null} 
            {img==="img-38" ? <img src={Img38} alt=""/> : null} 
            {img==="img-39" ? <img src={Img39} alt=""/> : null} 
            {img==="img-40" ? <img src={Img40} alt=""/> : null} 
            {img==="img-41" ? <img src={Img41} alt=""/> : null} 
            {img==="img-42" ? <img src={Img42} alt=""/> : null} 
            {img==="img-43" ? <img src={Img43} alt=""/> : null} 
            {img==="img-44" ? <img src={Img44} alt=""/> : null} 
            {img==="img-45" ? <img src={Img45} alt=""/> : null} 
            {img==="img-46" ? <img src={Img46} alt=""/> : null} 
            {img==="img-47" ? <img src={Img47} alt=""/> : null} 
            {img==="img-48" ? <img src={Img48} alt=""/> : null} 
            {img==="img-49" ? <img src={Img49} alt=""/> : null} 
            {img==="img-50" ? <img src={Img50} alt=""/> : null} 
            {img==="img-51" ? <img src={Img51} alt=""/> : null} 
            {img==="img-52" ? <img src={Img52} alt=""/> : null} 
            {img==="img-53" ? <img src={Img53} alt=""/> : null} 
            {img==="img-54" ? <img src={Img54} alt=""/> : null} 
            {img==="img-55" ? <img src={Img55} alt=""/> : null} 
            {img==="img-56" ? <img src={Img56} alt=""/> : null} 
            {img==="img-57" ? <img src={Img57} alt=""/> : null} 
            {img==="img-58" ? <img src={Img58} alt=""/> : null} 
            {img==="img-59" ? <img src={Img59} alt=""/> : null} 
            {img==="img-60" ? <img src={Img60} alt=""/> : null} 
            {img==="img-61" ? <img src={Img61} alt=""/> : null} 
            {img==="img-62" ? <img src={Img62} alt=""/> : null} 
            {img==="img-63" ? <img src={Img63} alt=""/> : null} 
            {img==="img-64" ? <img src={Img64} alt=""/> : null} 
            {img==="img-65" ? <img src={Img65} alt=""/> : null} 
            {img==="img-66" ? <img src={Img66} alt=""/> : null} 
            {img==="img-67" ? <img src={Img67} alt=""/> : null} 
            {img==="img-68" ? <img src={Img68} alt=""/> : null} 
            {img==="img-69" ? <img src={Img69} alt=""/> : null} 
            {img==="img-70" ? <img src={Img70} alt=""/> : null} 
            {img==="img-71" ? <img src={Img71} alt=""/> : null} 
            {img==="img-72" ? <img src={Img72} alt=""/> : null} 
            {img==="img-73" ? <img src={Img73} alt=""/> : null} 
            {img==="img-74" ? <img src={Img74} alt=""/> : null} 
            {img==="img-75" ? <img src={Img75} alt=""/> : null} 
            {img==="img-76" ? <img src={Img76} alt=""/> : null} 
            {img==="img-77" ? <img src={Img77} alt=""/> : null} 
            {img==="img-78" ? <img src={Img78} alt=""/> : null} 
            {img==="img-79" ? <img src={Img79} alt=""/> : null} 
            {img==="img-80" ? <img src={Img80} alt=""/> : null} 
            {img==="img-81" ? <img src={Img81} alt=""/> : null} 
            {img==="img-82" ? <img src={Img82} alt=""/> : null} 
            {img==="img-83" ? <img src={Img83} alt=""/> : null} 
            {img==="img-84" ? <img src={Img84} alt=""/> : null} 
            {img==="img-85" ? <img src={Img85} alt=""/> : null} 
            {img==="img-86" ? <img src={Img86} alt=""/> : null} 
            {img==="img-87" ? <img src={Img87} alt=""/> : null} 
            {img==="img-88" ? <img src={Img88} alt=""/> : null} 
            {img==="img-89" ? <img src={Img89} alt=""/> : null} 
            {img==="img-90" ? <img src={Img90} alt=""/> : null} 
            {img==="img-91" ? <img src={Img91} alt=""/> : null} 
            {img==="img-92" ? <img src={Img92} alt=""/> : null} 
            {img==="img-93" ? <img src={Img93} alt=""/> : null} 
            {img==="img-94" ? <img src={Img94} alt=""/> : null} 
            {img==="img-95" ? <img src={Img95} alt=""/> : null}
            {img==="img-96" ? <img src={Img96} alt=""/> : null}
            {img==="img-97" ? <img src={Img97} alt=""/> : null}
            {img==="img-98" ? <img src={Img98} alt=""/> : null}
            {img==="img-99" ? <img src={Img99} alt=""/> : null}
            {img==="img-100" ? <img src={Img100} alt=""/> : null}
            {img==="img-101" ? <img src={Img101} alt=""/> : null}
            {img==="img-102" ? <img src={Img102} alt=""/> : null}
            {img==="img-103" ? <img src={Img103} alt=""/> : null}
            {img==="img-104" ? <img src={Img104} alt=""/> : null}
            {img==="img-105" ? <img src={Img105} alt=""/> : null}
            {img==="img-106" ? <img src={Img106} alt=""/> : null}
        </main>
    )
}

export default Image
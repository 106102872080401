import React  from 'react'
import '../../assets/css/environments.css'
import Background from '../../assets/images/Environments/background.webp'

function Environments (){
    return(
        <main>
            <div className='divisor'>
                <span>Environments</span>
            </div>
            <section id='Environments-Component'>
                <img src={Background} alt="Evironments Background" className='background-environments' id='Environments' />

                <section id='container-environments'>
                    <div className='environment'>
                        <aside className='image office'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                Offices
                            </span>
                            <span className='text-environment'>
                                Well-designed and well-appointed workspaces provide comfort, tranquillity, positive stimulus and increased productivity. This will clearly bring more significant results for your business!
                                <br/><br/>
                                Speaking of a trend that is becoming more and more established in our daily lives, where a large number of people are increasingly using their homes as their main place of work. The so-called home offices are environments created to bring more practicality and comfort to those who work at home.
                            </span>
                        </span>
                    </div>

                    <div className='environment right'>
                    <aside className='image bedroom'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                Bedrooms and wardrobes
                            </span>
                            <span className='text-environment'>
                                It seems obvious that the bedroom should be the first to be planned, as it is the part of the house where you spend most of your time, right? However, it is often neglected because it is usually only seen and used by the residents and there is not the pressure of visitors' eyes to make a room planned, beautiful or even functional.
                                <br/><br/>
                                More than just a comfortable bed, the bedroom must have other qualities to ensure the well-being of you and your family.
                            </span>
                        </span>
                    </div>

                    <div className='environment'>
                    <aside className='image livingroom'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                Living Room
                            </span>
                            <span className='text-environment'>
                                The living room is one of the most used environments of the house, because it is in the living room that we rest, receive visitors, spend good moments with family and friends, for these reasons, planning well every detail of decoration of this environment is a very important task.
                                <br/><br/>
                                When we think of well planned living room is necessary to bear in mind that every detail can make all the difference in this environment, leaving the most modern, intimate, cosy ...
                            </span>
                        </span>
                    </div>

                    <div className='environment right'>
                    <aside className='image kitchen'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                Kitchen
                            </span>
                            <span className='text-environment'>
                                Your kitchen deserves care and attention. It is, after all, an environment for happy moments with family or friends. It needs a beautiful project with well-designed furniture. This feature facilitates the organisation of the space.
                                <br/><br/>
                                The cupboards and drawers for storing utensils and accessories also give your interior a good dose of personality. Our kitchens are designed to make the most of the environment and offer you practicality in the use of your kitchen.
                                More than just a comfortable bed, the bedroom must have other qualities to ensure the well-being of you and your family.
                                <br/><a href="https://dossantosplumbingservices.uk/kitchen" className='seeMoreButton'>See more</a>
                            </span>
                        </span>
                    </div>

                    <div className='environment'>
                    <aside className='image bathroom'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                The bathroom
                            </span>
                            <span className='text-environment'>
                                The bathroom is an area of the house that requires careful planning, especially if it is to include a bathtube. It is necessary to choose a style of decoration that will work in the room, to recognise the trends, to evaluate the available dimensions and to make the right combinations of elements. It is therefore important to be bold in its decoration and to plan well so that it harmonises with the rest of the house.
                                <br/><br/>
                                The en-suite bathroom should be planned to suit you. It must be carefully planned to become a comfortable and pleasant space, without forgetting the practicality that you need on a daily basis.
                                <br/><a href="https://dossantosplumbingservices.uk/bathroom" className='seeMoreButton'>See more</a>
                            </span>
                        </span>
                    </div>
                    <div className='environment right'>
                    <aside className='image laundry'></aside>
                        <span className='description-environment'>
                            <span className='tittle-environment'>
                                Laundries
                            </span>
                            <span className='text-environment'>
                                You don't receive visitors in the laundry room, nor do you use it when you want to relax. But that doesn't mean this corner of the house should be forgotten.
                                <br/><br/>
                                On the contrary, the laundry room needs to be minimally organised so that you can carry out the necessary tasks more quickly and conveniently. In other words, it should be small but very functional.
                            </span>
                        </span>
                    </div>
                </section>
            </section>
            <span id='ContactUs'></span>
        </main>
    )
}

export default Environments
import React from 'react'
import Home from './components/Home/Home'
import Menu from './components/Menu/Menu'
import Environments from './components/Evironments/Environments'
import Contact from './components/Contact/Contact'
import Rodape from './components/Rodape/Rodape'

function App() {

  return (
    <div id="App-Container">
      <div className="wall"></div>
      <div className="wall"></div>
      <div className="wall"></div>
      <Menu/>
      <Home/>
      <Environments/>
      <Contact/>
      <Rodape/>
    </div>
  )
}

export default App

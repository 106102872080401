import React from "react";
import "../../assets/css/rodape.css"
import Call from "../../assets/images/Icons/mobile-phone.webp"
import Message from "../../assets/images/Icons/message.webp"
import Instagram from "../../assets/images/Icons/instagram.webp"
import Facebook from "../../assets/images/Icons/facebook.webp"

function Rodape(){
    return(
        <main id="rodape">
            <section className="rodape-contact">
                <section className="contact-rodape">
                    <span>CALL US ON</span>
                    <span><img src={Call} alt="call" className="img-rodape"/>+44 7775946061</span>
                </section>
                <section className="button-rodape">
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" className="instagram-rodape">
                        CHECK OUT OUR INSTAGRAM
                    </a>
                </section>
            </section>
            <section className="rodape-informations">
                <section>
                    <div>
                        Dos Santos
                        <span>Plumbing Services</span>
                    </div>
                </section>
                <section>
                    <div>Check it out</div>
                    <ul>
                        <li><a href="https://dossantosplumbingservices.uk/#Home">Home</a></li>
                        <li><a href="https://dossantosplumbingservices.uk/#Environments">Environments</a></li>
                        <li><a href="https://dossantosplumbingservices.uk/buildings">Buildings</a></li>
                        <li><a href="https://dossantosplumbingservices.uk/#ContactUs">Contact</a></li>
                    </ul>
                </section>
                <section>
                    <div>
                        Follow Us
                    </div>
                    <a href="https://www.instagram.com/dossantos_plumbingservices?igsh=NG90bTdqOHg5NTR2" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="instagram icon"/>Instagram</a>
                    <a href="https://www.facebook.com/110319171987599/posts/pfbid02Thqh5zXSQs4e9X55rjfmt6Ex2Zt2n6bvsxDtesHz7JbkyE9TwxHzK961VkcAeJ3Vl/?sfnsn=scwspwa" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="facebook icon"/>Facebook</a>
                </section>
                <section>
                    <div>Contact</div>
                    <span><img src={Message} alt="message icon"/> contact<br/>@dossantosplumbingservices.uk</span>
                    <span><img src={Call} alt="call icon"/>+44 7775946061</span>
                </section>
            </section>
            <section className="rodape">
                <a href="#Home">Dos Santos Plumbing Services</a>&nbsp; - 2023 © All rights reserved | Developed by: &nbsp; <a href="https://github.com/DiegoXavier-hub"> DHX</a>
            </section>
        </main>
    )
}

export default Rodape;